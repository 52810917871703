import { Component } from "react";
import "./Susana.scss";

class Susana extends Component {
  render() {
    return (
      <div>
        <p class="project-example">
          &emsp;&emsp;„Czy ja kupowałem to w zeszłym tygodniu, czy może dwa
          tygodnie temu? I jaką to wtedy miało datę ważności? Nie sposób tego
          wszystkiego spamiętać. A co z tymi zakupami które zrobiłem przez
          Internet? Nie zadzwonię przecież do nikogo o tej porze…”
        </p>
        &emsp;&emsp;Susana to aplikacja rozpoznająca i czytająca daty
        przydatności produktów spożywczych i konsumenckich. W łatwy i
        funkcjonalny sposób pozwoli naprowadzać Twojego smartfona w odpowiednie
        miejsce, a następnie zakomunikuje datę ważności. Podstawowa aplikacja
        będzie dalej rozwijana, dodane zostaną takie funkcjonalności jak
        możliwość zapisu daty i kalendarza, który przypomni, że mija niedługo
        data ważności wybranego produktu. Susana przeznaczona jest zarówno dla
        systemu Android, jak i iOS.
      </div>
    );
  }
}

export default Susana;
