import { Component } from "react";
import "./Emily.scss";

class Emily extends Component {
  render() {
    return (
      <div>
        <p className="project-example">
          &emsp;&emsp;„Dlaczego muszę dowiadywać się od kogoś, o tym że będę
          mamą? Muszę przecież komuś pokazać ten test! Nie chcę z nim o tym
          rozmawiać, dopóki sama nie będę pewna! Przecież nie zapytam o to
          rodziców!""
        </p>
        &emsp;&emsp;Emily to aplikacja, która umożliwi samodzielne odczytanie
        testu ciążowego. Aplikacja przeznaczona będzie zarówno dla systemu
        Android, jak i iOS.
      </div>
    );
  }
}

export default Emily;
