import { Component } from "react";

class Team extends Component {
  render() {
    return (
      <div>
        <h3>Artur Godlewski</h3>
        <p>
          &emsp;&emsp;Pomysłodawca i Project Manager, doświadczenie w projektach
          badawczych i branży IT. Chce realizować projekty, które pomagają
          osobom niewidomym w codziennym życiu.
        </p>
        <h3>dr Wojciech Figiel</h3>
        <p>
          &emsp;&emsp;W projekcie odpowiada za testy aplikacji z niewidomymi i
          słabowidzącymi użytkownikami. Ponieważ sam jest osobą niewidomą, to on
          dba o to, by pomysły całego zespołu odpowiadały na rzeczywiste
          potrzeby grupy docelowej. Jego pasją jest tłumaczenie. Nie tylko jest
          aktywnym tłumaczem ustnym i pisemnym, lecz także naucza innych tego
          zawodu. Na co dzień pracuje jako adiunkt w Zakładzie Badań nad
          Przekładem Ustnym i Audiowizualnym w Instytucie Lingwistyki Stosowanej
          Uniwersytetu Warszawskiego.
        </p>
        <h3>Jarosław Mąka </h3>
        <p></p>
        &emsp;&emsp;Python developer z doświadczeniem w różnych branżach i
        frameworkach. Łączy bogate doświadczenie zawodowe w IT, pasję do
        programowania oraz analizy danych do tworzenia programów pozwalających
        maszynom widzieć.
        <h3>Monika Falk</h3>
        <p>
          &emsp;&emsp;Programistka z szerokim doświadczeniem w technologiach
          webowych, magister inżynier Informatyki oraz Inżynierii Środowiska.
          Doktorantka Politechniki w Ostrawie na kierunku Informatyka ze
          specjalizacją Uczenie Maszynowe. Chciałaby wykorzystać sztuczna
          inteligencje do pomocy osobom neuroatypowym, tworząc roboty społeczne
          czy aplikacje związane z rozpoznawaniem emocji.
        </p>
        <h3>Agnieszka Mikołajczyk</h3>
        <p>
          &emsp;&emsp;Autorka zbiorów danych, prac naukowych i publikacji,
          laureatka licznych stypendiów i nagród. Na co dzień prowadzi badania w
          ramach grantu Preludium "Detecting and overcoming bias in data with
          explainable artificial intelligence", przyznanego przez Polskie
          Narodowe Centrum Nauki (biasinml.netlify.app). Pracuje nad różnymi
          zagadnieniami NLP w swoim zespole R&D w Voicelab.ai. Jest
          współorganizatorką zadań PolEval2021 i PolEval 2022 dotyczących
          przewidywania i odtwarzania znaków interpunkcyjnych. W wolnym czasie
          organizuje i aktywnie udziela się w środowisku naukowym: wspólnie z
          zespołem zorganizowała, a następnie kierowała badaniami w projekcie
          HearAI, którego celem było wsparcie środowiska Głuchych poprzez
          modelowanie języka migowego z wykorzystaniem głębokiego uczenia
          maszynowego (hearai.pl). Była organizatorka i liderka zespołu w
          projekcie open-source detectwaste.ml.
        </p>
      </div>
    );
  }
}

export default Team;
