import { Component } from "react";
import "./Isabelle.scss";

class Isabelle extends Component {
  render() {
    return (
      <div>
        <p className="project-example">
          &emsp;&emsp;„O, chyba przyjechał mój autobus. Chwila, są dwa. Który
          jest mój? Jak zwykle nikogo nie ma na przystanku. Odjechał. Kierowca
          chyba nie zauważył że machałam. Jest następny. Udało się. Szkoda
          tylko, że nie włączył zapowiedzi dźwiękowych. Chwila, czy on tu zawsze
          skręca? Czy to nie jest trasa do zajezdni?”
        </p>
        &emsp;&emsp;Isabelle to aplikacja, która ma za zadanie wspomagać osoby
        niewidome we wsiadaniu do właściwego autobusu. Mimo swojej ograniczonej
        funkcjonalności, chcemy by jej siłą była wysoka skuteczność i łatwość
        obsługi, co pozwoli osobom niewidomym pewnie i jeszcze bardziej
        samodzielnie korzystać z komunikacji miejskiej. Aplikacja przeznaczona
        jest zarówno dla systemu Android, jak i iOS.
      </div>
    );
  }
}

export default Isabelle;
