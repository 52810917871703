import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const firebaseConfig = {
  apiKey: "AIzaSyD2ptI9BWOZjF_Pec8YLiHoELS6fuyMGvw",
  authDomain: "zatoichi-77522.firebaseapp.com",
  projectId: "zatoichi-77522",
  storageBucket: "zatoichi-77522.appspot.com",
  messagingSenderId: "973387978337",
  appId: "1:973387978337:web:4f5c177291cd2d845a3c20",
  measurementId: "G-5SSXEZ9NSG",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
