import { Component } from "react";
import "./Contact.scss";

class Contact extends Component {
  render() {
    return (
      <div className="contact">
        <div className="email">kontakt@zatoichi.pl</div>
        <div className="phone">
          tel: <span class="text-bold">+48 507 301 995</span>
        </div>
      </div>
    );
  }
}

export default Contact;
