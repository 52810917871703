import { Component } from "react";

class Goal extends Component {
  render() {
    return (
      <div>
        <p>
          &emsp;&emsp;Naszym celem jest tworzenie bazującego na machine
          learningu oprogramowania wspierającego osoby niewidome i słabowidzące
          w codziennym życiu. W naszych rozwiązaniach skupiamy się na tym, by
          oprogramowanie było tworzone przy aktywnym udziale osób, dla których
          jest przeznaczone. Bardzo ważne jest dla nas, by oferowane rozwiązania
          były maksymalnie proste i godne zaufania. Ponadto jesteśmy otwarci na
          inne projekty, które są z pogranicza computer vision i pattern
          recognition.
        </p>
      </div>
    );
  }
}

export default Goal;
