import { Component } from "react";
import "./Footer.scss";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="ue-logos">
          <img src="/images/ue.jpg" alt="Fundusze europejskie" />
        </div>
      </div>
    );
  }
}

export default Footer;
