import { Component } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import "./Navbar.scss";

const LINKS = [
  { id: 0, link: "goal", displayName: "Cel" },
  {
    id: 1,
    link: "projects/susana",
    displayName: "Czytanie dat ważności (Susana)",
  },
  {
    id: 2,
    link: "projects/isabelle",
    displayName: "Czytanie numerów autobusów (Isabelle)",
  },
  { id: 3, link: "projects/emily", displayName: "Czytanie testów (Emily)" },
  { id: 4, link: "team", displayName: "Zespół" },
  { id: 5, link: "contact", displayName: "Kontakt" },
];

function DisplayLinks() {
  const navigate = useNavigate();
  return LINKS.map((link) => (
    <NavLink
      to={link.link}
      key={link.id.toString()}
      className={[
        ({ isActive }) => (isActive ? "active" : "inactive"),
        "nav-item",
      ].join(" ")}
      onClick={() => navigate(link.link)}
    >
      {link.displayName}
    </NavLink>
  ));
}

class Navbar extends Component {
  render() {
    return (
      <div className="navbar-container">
        <div className="logo">
          <img src="/images/zatoichi.bmp" alt="Logo firmy Zatoichi" />
        </div>
        <nav className="navbar">
          <DisplayLinks />
        </nav>
      </div>
    );
  }
}
export default Navbar;
