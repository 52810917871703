import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import Contact from "./pages/general/contact/Contact";
import Goal from "./pages/general/goal/Goal";
import Team from "./pages/general/team/Team";
import Emily from "./pages/projects/emily/Emily";
import Isabelle from "./pages/projects/isabelle/Isabelle";
import Susana from "./pages/projects/susana/Susana";
import Footer from "./_core/components/general/footer/Footer";
import Navbar from "./_core/components/general/navbar/Navbar";

function App() {
  return (
    <div className="App">
      <div className="layout">
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/goal" element={<Goal />} />
            <Route path="/projects/emily" element={<Emily />} />
            <Route path="/projects/isabelle" element={<Isabelle />} />
            <Route path="/projects/susana" element={<Susana />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/team" element={<Team />} />
            <Route exact path="/" element={<Navigate replace to="/goal" />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
